
import React, {useState} from "react"
import Layout from "../../layout"

import WebappsForm from "../form"


const SEARCHMODEID_ALL = 0;
const SEARCHMODEID_USERTYPE = 1;
const SEARCHMODEID_TABLENAME = 2;

const ENTITYIDLIST=["panelaccess", "usertype", "panelgroup"];

const panelgrouppickerfields = [
	{"label":"Name", "dbfield": "panelgroup_name", "type": "text", "filtertype": "textbox"},
];

const panelgrouppickerfilter = [
	{"field":"panelset_id", "operation": ">", "value": 6},
];

const usertypepickerfields = [
	{"label":"Name", "dbfield": "usertype_name", "type": "text", "filtertype": "textbox"},
	{"label":"Description", "dbfield": "usertype_description", "type": "text", "filtertype": "textbox"},
];

const usertypepickerfilter = [
	{"field":"usertype_id", "operation": ">", "value": 3},
];



/*

	function menureset_deleteaccess($groupid, $usertypeid)
	{
		db_query("delete from PANELACCESS where panelgroup_id=$groupid and usertype_id=$usertypeid");
	}
	function permission_getsetlist()
	{
		return db_query("select panelset_id,panelset_name from PANELSET where panelset_id<>".PANELSET_HIDDEN);
	}

	function permission_getuserpermission($usertype, $groupid)
	{
		return db_getqueryid("select panelaccess_accessallow from PANELACCESS where usertype_id=$usertype and panelgroup_id=$groupid");
	}

	function permission_updateuserpermission($groupid, $usertypeid, $accessallow)
	{
		// assumes unique index
		db_query("insert into PANELACCESS (panelgroup_id, usertype_id,panelaccess_accessallow) VALUES ($groupid,$usertypeid, $accessallow)");
		db_query("update PANELACCESS set panelaccess_accessallow=$accessallow  where panelgroup_id=$groupid and usertype_id=$usertypeid");
	}


*/
const searchFieldsList = [
	[
		{"label":"User Type", "dbfield": "usertype_name", "type": "text", "filtertype": "text"},
		{"label":"Table/Entity", "dbfield": "panelgroup_name", "type": "text", "filtertype": "text"},
		{"label":"Access Type", "dbfield": "panelaccess_accessallow", "type": "custom", "filtertype": "integer"}
	],
	usertypepickerfields,
	panelgrouppickerfields
];


const formFieldsList = [
	[
		[
			{"label":"User Type", "field": "usertype_name", "value": "", "input": "picker", "mode": "unique", "pickerfields": usertypepickerfields, "pickerfilters": usertypepickerfilter},
			{"label":"Table/Entity", "field": "panelgroup_name", "value": "", "input": "picker", "mode": "unique", "pickerfields": panelgrouppickerfields, "pickerfilters": panelgrouppickerfilter},
			{"label":"Access Type", "field": "panelaccess_accessallow", "value": "", "input": "combo", "mode": "required", "options": [
				{"value":2,"display":"View"},
				{"value":8,"display":"Add/Edit"},
				{"value":128,"display":"Full"},
			], "info":"Delete to remove permission"},

		]
	],
	[
		[
			{"label":"Name", "field": "usertype_name", "value": "", "input": "textbox", "mode": "readonly"},
			{"label":"Description", "field": "usertype_description", "value": "", "input": "textbox", "mode": "readonly"},
		]
	],
	[
		[
			{"label":"Table/Entity", "field": "panelgroup_name", "value": "", "input": "textbox", "mode": "readonly"}
		],
	]
];

const subformFieldsList = [
	[],
	[
		{
			"subformid": "panelaccess",
			"label": "Table/Entity",
			"table": "panelaccess",
			"sort": "panelgroup_name",
			"mobilerowtitlefieldidx":[0],
			"minrow": 0,
			"maxrow": 100,
			"allowdel": true,
			"fieldlist": [
				{
					"label":"Table/Entity",
					"field": "panelgroup_name",
					"value": "",
					"input": "picker",
					"mode": "unique",
					"pickerfields": panelgrouppickerfields,
					"pickerfilters": panelgrouppickerfilter
				},
				{
					"label":"Access Type",
					"field": "panelaccess_accessallow",
					"value": "",
					"input": "combo",
					"mode": "required",
					"options": [
						{"value":2,"display":"View"},
						{"value":8,"display":"Add/Edit"},
						{"value":128,"display":"Full"},
					]
				},
			],
			"footerlist": [
			]
		},
	],
	[
		{
			"subformid": "panelaccess",
			"label": "User Types",
			"table": "panelaccess",
			"sort": "usertype_name",
			"mobilerowtitlefieldidx":[0],
			"minrow": 0,
			"maxrow": 100,
			"allowdel": true,
			"fieldlist": [
				{
					"label":"User Type",
					"field": "usertype_name",
					"value": "",
					"input": "picker",
					"mode": "unique",
					"pickerfields": usertypepickerfields,
					"pickerfilters": usertypepickerfilter
				},
				{
					"label":"Access Type",
					"field": "panelaccess_accessallow",
					"value": "",
					"input": "combo",
					"mode": "required",
					"options": [
						{"value":2,"display":"View"},
						{"value":8,"display":"Add/Edit"},
						{"value":128,"display":"Full"},
					]
				},
			],
			"footerlist": [
			]
		},
	]
]

const searchFilterList = [
	usertypepickerfilter,
	usertypepickerfilter,
	panelgrouppickerfilter,
]

const mobilerowtitleFieldList = [
	["usertype_name", "panelgroup_name"],
	["usertype_name"],
	["panelgroup_name"]
]

const UserpermissionsForm = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	function defaultCustomSearchField(field, idx, rowdata, styles)
	{
		if (field.dbfield === "panelaccess_accessallow") {
			const curval = parseInt(rowdata[field.dbfield], 10);
			var tmpout = curval+" (";
			if (curval === 2) {
				tmpout=tmpout+"View";
			} else if (curval === 8) {
				tmpout=tmpout+"Add/Edit";
			} else if (curval === 128) {
				tmpout=tmpout+"Full";
			}
			return <>{tmpout+")"}</>
		}
		return <></>
	}


	function checkduplicatesubformentry(subformdata, subformname, dbfield)
	{
		var refrowidx = 0;
		var rowidx = 0;
		var colidx = 0;

		if (rowidx < subformdata.length) {
			colidx = 0;
			while (colidx < subformdata[rowidx].length) {
				if (subformdata[rowidx][colidx].field === dbfield) {
					break;
				}
				colidx++;
			}
			if (colidx >= subformdata[rowidx].length) {
				// dbfield Not found
				return {"status":"OK"};
			}
		} else {
			return {"status":"OK"};
		}

		refrowidx = 0;
		while (refrowidx < subformdata.length-1) {
			if (parseInt(subformdata[refrowidx][0].value,10)===0) {
				// Not marked for deletion, skip
				rowidx = refrowidx+1;
				while(rowidx < subformdata.length) {
					if (parseInt(subformdata[rowidx][0].value,10)===0) {
						if (subformdata[rowidx][colidx].value === subformdata[refrowidx][colidx].value) {
							return {"status":"Error", "message":"Duplicated "+subformname+" "+subformdata[refrowidx][colidx].label+" at rows "+(refrowidx+1)+" and "+(rowidx+1)};
						}
					}
					rowidx++;
				}
			}
			refrowidx++;
		}
		return {"status":"OK"};
	}

	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		if (searchmode === SEARCHMODEID_ALL) {
			return {"status":"OK"};
		}
		var tmpres = {};
		var idx = 0;

		idx = 0;
		while (idx < subformFieldsList[searchmode].length) {
			if (subformFieldsList[searchmode][idx].table === "panelaccess") {
				tmpres = checkduplicatesubformentry(subformlist[idx], subformFieldsList[searchmode][idx].label, searchmode === SEARCHMODEID_USERTYPE?"panelgroup_id":"usertype_id");
				if (tmpres.status !== "OK") {
					return tmpres;
				}
			}
			idx++;
		}
		return {"status":"OK"};
	}

	function setNewMode(e, newmode)
	{
		if (e) {
			e.preventDefault();
		}
		const querystring = newmode===SEARCHMODEID_ALL?"":"?mode="+newmode;
		window.open(location.pathname+querystring, "_top");
	}

	var queryparam = {};
;	var querystring = "";

	if (location.hasOwnProperty("search")) {
		if (location.search) {
			querystring = location.search;
		}
	}

	if (querystring.length > 0) {
		const tmpparamlist = Array.from(new URLSearchParams(querystring));
		var tmpidx = 0;
		while (tmpidx < tmpparamlist.length) {
			if (tmpparamlist[tmpidx][1] !== null) {
				queryparam[tmpparamlist[tmpidx][0]] = tmpparamlist[tmpidx][1];
			}
			tmpidx++;
		}
	}

	const searchmode = queryparam.hasOwnProperty("mode")?parseInt(queryparam.mode,10):SEARCHMODEID_ALL;

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}>
				<br/>
				<div className="text-centered">

					{searchmode !== SEARCHMODEID_ALL&&<button onClick={(e)=>{setNewMode(e, SEARCHMODEID_ALL);}}>All</button>}

					&nbsp;&nbsp;&nbsp;

					{searchmode !== SEARCHMODEID_USERTYPE &&<button onClick={(e)=>{setNewMode(e, SEARCHMODEID_USERTYPE);}}>by User Type</button>}

					&nbsp;&nbsp;&nbsp;

					{searchmode !== SEARCHMODEID_TABLENAME&&<button onClick={(e)=>{setNewMode(e, SEARCHMODEID_TABLENAME);}}>by Table</button>}
				</div>
				<WebappsForm
					location={location}
					allowadd={searchmode === SEARCHMODEID_ALL}
					allowdelete={searchmode === SEARCHMODEID_ALL}
					pagetitle={pagetitle}
					mobilerowtitlefield={mobilerowtitleFieldList[searchmode]}
					searchFields={searchFieldsList[searchmode]}
					editFields={formFieldsList[searchmode]}
					editSubFormFields={subformFieldsList[searchmode]}
					userSearchFilter={searchFilterList[searchmode]}
					customSearchField={defaultCustomSearchField}
					customValidateValues={formValidateValues}
					entity={searchmode>=0?ENTITYIDLIST[searchmode]:ENTITYIDLIST[0]}
					token={token} />
		</Layout>
}


export default UserpermissionsForm;
